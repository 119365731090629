<template>
  <div class="bg">
    <p class="ml-3 mt-3">
      User Id: {{ yourId }}<br/>
      Initiator: {{ initiator }}<br/>
      Room Id: {{ roomName }}<br/>
      sent: {{ messageSent }}<br/>
      received: {{ messageReceived }}
    </p>
    <h1>Debug</h1>
    <p>Outgoing: {{ outgoing }}</p>
    <p>Incoming:</p>
    <textarea v-model="incoming"></textarea><br/>
    <button @click="submit">submit</button>
  </div>
</template>

<script>
import { database } from '@/services/db';
import { getLog } from "@/services/log";
import Peer from "simple-peer";
let log = getLog('test');

export default {
  name: 'app',
  data() {
    return {
      p: null,
      yourId: 0,
      initiator: false,
      rootDb: null,
      roomName: "room1",

      // Message
      messageSent: "",
      messageReceived: "",

      // Debug
      incoming: "",
      outgoing: []      
    }
  },
  mounted() {
    this.yourId = Math.floor(Math.random()*1000000000);
    log.log("userid=", this.yourId);

    this.init();
  },
  methods: {
    submit() {
      this.p.signal(JSON.parse(this.incoming));
    },
    init() {
      log.log("init");
      let that = this;
      this.rootDb = database.ref(`LiveCalls/${this.roomName}`);

      this.initiator = this.$route.query.initiator ? true : false;
      let p = new Peer({
        initiator: this.initiator,
        trickle: false
      });

      p.on('error', err => log.log('error', err));

      p.on('signal', data => {
        log.log('SIGNAL', data)
        let message = JSON.stringify(data);
        this.outgoing.push(message);
        this.sendMessage(this.yourId, message);
      });

      p.on('connect', () => {
        this.messageSent = 'whatever' + Math.random();
        log.log('CONNECT', this.messageSent);
        p.send(this.messageSent);
      });

      p.on('data', data => {
        this.messageReceived = data;
        log.log('data: ' + data)
      });

      this.p = p;

      // On message on firebase
      this.rootDb.on('child_added', (data) => {
        var msg = JSON.parse(data.val().message);
        var sender = data.val().sender;
        if (sender != that.yourId) {
          log.log("new message in fb", msg);
          that.incoming = JSON.stringify(msg);
          that.submit();
        }
      });
    },
    sendMessage(senderId, data) {
      log.log("sendMessage", senderId, data);
      var msg = this.rootDb.push({ sender: senderId, message: data });
      msg.remove();
    }
  }
}
</script>

<style scoped>

.bg {
  background-color: bisque;
}

textarea {
  width: 600px;
  height: 600px;
}

</style>
